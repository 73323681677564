import { Link } from "@nextui-org/react";
import { useLocation, useParams } from "react-router-dom";

export default function Footer() {
    
    if(window.location.pathname == "/build") return <></>

    return (
        <div id="footer" className="border-t text-center p-4">
            <Link href="/privacy" className="mr-2" showAnchorIcon target="_">Privacy Policy</Link>
            <Link href="/contact" showAnchorIcon target="_">Support</Link>

            <div className="text-tiny">
                &#169; 2025
            </div>
        </div>
    )
}