
// todo 

import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { faHammer, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Image, Link, Spacer } from "@nextui-org/react";

export default function CheckoutSuccess() {
    return (
       <div className="p-4 text-xl" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Image src="/logo.png" width={200} />
            <Spacer y={10} />

            Thank you for your business! Please check your email for a confirmation email with more details.

            <Spacer y={3} />
            <Button as={Link} href="/" startContent={<FontAwesomeIcon icon={faHome} />}>Home</Button>


            {/* <Spacer y={5} />
            <Logo /> */}
       </div>
    )
}


// function Logo() {
//     const [image, setImage] = useState<string | null>();

//     const makeLogo = async () => {
//         const element =  document.getElementById("logo-div")!;
//         const canvas = await html2canvas(element, {
//             scale: 5, // scale up to increase image quality
//             backgroundColor: 'transparent',
            
//         });
//         //const blob: string = await new Promise(resolve => canvas.toDataURL(resolve));
//         setImage(canvas.toDataURL());
//     }

//     return (
//         <div style={{backgroundColor: 'black', width: '200px', height: '200px'}}>
//             <div id="logo-div" style={{zIndex: '1000', width: '200px', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
//                 //border: '2px solid red',
//                 borderRadius: '50%',
//                 backgroundColor: '#006fee33'
//                 //background: 'rgb(56,189,248) linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)'
//             }}>
//                 <div>
//                     B
//                 </div>
//                 {/* <FontAwesomeIcon icon={faIdCard} fontSize={'125px'} style={{color: '#99c7fb'}} />             */}
//             </div>


//             <Spacer y={10} />
//             <Button onClick={makeLogo} color="primary" variant="flat">Make logo</Button>

//             <img src={image!} />
//         </div>
//     )
// }