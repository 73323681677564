import "./About.css"
import { faArrowRight, faCheck, faChevronDown, faDumbbell, faHammer, faMagnifyingGlass, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Link, Spacer, Button, CircularProgress, Image } from "@nextui-org/react";
import { useEffect, useState } from "react";
import Face from "../Builder/Components/face/face";
import useSWR from "swr";
import { getTemplates } from "../../utils";
import FAQ from "../../Components/faq";
import { appInsights } from "../../App-Insights";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";


export default function About() {
    const goToInfo = () => {
        document.getElementById("info-section")!.scrollIntoView({
            behavior: "smooth"
        })
    }

    return (
        <div style={{paddingTop: '90px', paddingLeft: '30px', paddingRight: "30px"}}>
            <div id="editor-container">
                <div>
                    <div className="text-5xl text-white font-medium">
                        <span className="decoration-warning decoration-2 underline underline-offset-8">Badges</span>, Built <ForYouByYou/> You
                    </div>

                    <Spacer y={7} />

                    <div className="text-default-600">Professional, High Quality ID Badges</div>

                    <Spacer y={7} />

                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                        <Button 
                            variant="bordered"
                            endContent={<FontAwesomeIcon icon={faChevronDown}/>}
                            onClick={goToInfo}
                        >
                            Learn More
                        </Button>
                    </div>
                </div>

                <LandingGrabber />
            </div>

            <Spacer y={40} className="d-big"/>

            <div id="info-section" style={{paddingTop: '5rem'}}></div>

            <div className="lg:p-10" >

                <div className="bg-zinc-500 p-4 lg:p-10 rounded-xl">
                    <div style={{display: 'flex', gap: '50px', justifyContent: 'center', alignItems: 'center', maxWidth: '80%', margin: 'auto', flexWrap: 'wrap'}}>
                        <Image
                            src={`${process.env.REACT_APP_ASSETS_PATH}/images/what-we-do.jpg`} 
                            className="w-[400px]"
                        />

                        <div className="w-[600px]">
                            <div className="text-md">Overview</div>
                            <Spacer y={2} />
                            <div className="text-3xl font-bold">
                                Create Great Looking Employee Badges 
                            </div>
                            <Spacer y={5} />
                            <div className="text-lg">
                                {/* Build A Badge offers a quick and easy solution to creating employee IDs. We have badges that can be customized and a powerful editor to build your own. 
                                <Spacer /> */}
                                Scroll down to learn more about our features, badges, printing, and pricing.
                            </div>
                            <Spacer y={5} />
                            <Button size="lg" as={Link} href="contact" color="default" variant="solid" endContent={<FontAwesomeIcon icon={faArrowRight} />}>Contact Us</Button>
                        </div>
                    </div>

                </div>

                <Spacer y={40} />

                <div className="p-4 lg:p-10 rounded-xl">
                    <div className="flex text-white" style={{gap: '50px', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto'}}>
                        <div className="">
                            <div className="lg:text-5xl text-3xl font-light">Features</div>
                            <Spacer y={10} />

                            <div className="text-lg" style={{maxWidth: '400px'}}>
                                At Build A Badge, we want to make building a badge an easy and hassle free experience. That is why we offer a rich set of badge options and features. 
                            </div>
                        </div>

                        <div className="text-lg lg:text-2xl lg:grid " style={{gridTemplateColumns: '1fr 1fr', gap: '20px'}}>
                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                Free Download
                            </div>

                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                Printing
                            </div>

                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                No Account Required 
                            </div>

                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                Same Day Processing
                            </div>
                            
                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                Punch Hole 
                            </div>

                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                Color
                            </div>

                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                Portrait / Landscape
                            </div>

                            <div>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                Import / Export 
                            </div>
                        </div>

                        <Image
                            src={`${process.env.REACT_APP_ASSETS_PATH}/images/holding-badge-landscape.png`}
                            className="w-[400px]"
                        />
                    </div>
                </div>

                <Spacer y={40} />

                <div className="bg-emerald-950 rounded-xl">
                    <div className="info-container right-info-container">
                        <Image
                            src={`${process.env.REACT_APP_ASSETS_PATH}/images/inspiration.jpg`}
                            className="info-container-image"
                        />

                        <div className="p-4 lg:p-10">
                            <div className="lg:text-5xl text-3xl font-light" style={{margin: 'auto'}}>
                                Find Inspiration
                            </div>

                            <Spacer y={10} />

                            <div className="text-lg">
                                We have a wide range of fully customizable professional looking badges. Browse our collection and choose the badge right for you. All templates are customizable, allowing you to change any image, text, background, and badge layout.
                            </div>

                            <Spacer y={10} />

                            <div>
                                <Button
                                    color="primary"
                                    variant="solid"
                                    as={Link}
                                    href="badges"
                                    size="lg"
                                    endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                                    onClick={() => {
                                        appInsights.trackEvent({
                                            name: 'Green Browse Button'
                                        })
                                    }}
                                >
                                    Browse Badges
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <Spacer y={40} />

                <div className="bg-warning text-black rounded-xl">
                    <div className="info-container left-info-container">
                        <Image
                            src={`${process.env.REACT_APP_ASSETS_PATH}/images/custom.jpg`}
                            className="info-container-image"
                        />

                        <div className="p-4 lg:p-10">
                            <div className="lg:text-5xl text-3xl font-light" style={{margin: 'auto'}}>
                                Build Your Own
                            </div>

                            <Spacer y={10} />

                            <div className="text-lg">
                                Have an idea? Start building your very own custom badge from scratch with our powerful badge editor. You can add your own images, text, background, and customize the badge layout. Export your badge after desiging it to reuse it later. 
                            </div>

                            <Spacer y={10} />

                            <div>
                                <Button
                                    color="warning"
                                    variant="faded"
                                    as={Link}
                                    href="build"
                                    size="lg"
                                    endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                                    onClick={() => {
                                        appInsights.trackEvent({
                                            name: 'Yellow Build Button'
                                        })
                                    }}
                                >
                                    Get Started
                                </Button>
                            </div> 
                        </div>
                    </div>
                </div>

                <Spacer y={40} />

                <div className="p-4 lg:p-10 rounded-xl">
                    <div className="flex" style={{gap: '100px', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'}}>
                        <Image
                            src={`${process.env.REACT_APP_ASSETS_PATH}/images/printer.jpg`}
                            className="w-[400px]"
                        />

                        <div>
                            <div className="lg:text-5xl text-3xl font-light">
                                Printing and Downloading
                            </div>

                            <Spacer y={10} />

                            <div className="text-lg" style={{ maxWidth: '600px'}}>
                                Badge printers cost thousands of dollars and are difficult to maintain. Save time and money by letting us print your badges for you.
                           
                                <Spacer y={10} />

                                You can also download your badge design <span className="underline">for free</span> and print it yourself. 
                            </div>
                        </div>
                    </div>
                </div>

                <Spacer y={40} />

                <div className="p-4 lg:p-10 border">
                    <div id="quick-features-container">
                        <div>
                            <FontAwesomeIcon icon={faUserTie} style={{fontSize: '55px'}}/>
                            <Spacer y={5} />
                            <p className="text-lg">Professional, High Quality Badges are added frequently to suit your needs</p>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faCalendarCheck} style={{fontSize: '55px'}}/>
                            <Spacer y={5} />
                            <p className="text-lg">Same day processing so you get your badges as soon as possible</p>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faDumbbell} style={{fontSize: '55px'}}/>
                            <Spacer y={5} />
                            <p className="text-lg">Easy to use and powerful badge editor allows you to build great looking badges quickly</p>
                        </div>
                    </div>
                </div>

                <Spacer y={40} />

                <div className="bg-gray-900 rounded-xl lg:p-10 p-4">
                    <div className="lg:text-5xl text-3xl font-light text-center">Discounted Pricing</div>
                    <Spacer y={10} />
                    <div id="discounted-prices-container">
                        <div className="text-lg">
                            <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                                <div>
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                <div>
                                    Get <span className="font-bold">50% off</span> when you order your first badge and <span className="font-bold">25% off</span> by ordering more than one badge. 
                                </div>
                            </div>
                            <Spacer y={10} />
                            <Image
                                src={`${process.env.REACT_APP_ASSETS_PATH}/images/payment.jpg`}
                                className="discounted-prices-image"  
                            />
                        </div>
                        <div className="text-lg">
                            <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                                <div>
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                <div>
                                    Get <span className="font-bold">FREE SHIPPING</span> on all orders regardless of badge type and quantity 
                                </div>
                            </div>
                            <Spacer y={10} />
                            <Image
                                src={`${process.env.REACT_APP_ASSETS_PATH}/images/shipping.jpg`}
                                className="discounted-prices-image"  
                            />
                        </div>
                    </div>

                    <Spacer y={10} />

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button 
                            as={Link} 
                            href="/pricing"
                            endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                            size="lg"
                            variant="bordered"
                        >
                            Learn more about pricing
                        </Button>
                    </div>
                </div>

                <Spacer y={40} />

                <div className="lg:p-10 p-4" style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px'}}>
                    <div className="blur grayscale-[50%]" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: `url('${process.env.REACT_APP_ASSETS_PATH}/images/badges.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed'}}></div>
                    <Card className="bg-violet-300 text-black">
                        <Image
                            src={`${process.env.REACT_APP_ASSETS_PATH}/images/holding-badge.png`}
                            className="object-cover lg:w-[600px]"
                        />
                        <div className="lg:p-10 p-4">
                            <div className="text-3xl">Start Building Your Badge Now</div>
                            <Spacer y={5} />

                            <div className="text-lg">Build your own badge from scratch or search for a template</div>

                            <Spacer y={5} />

                            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px'}}>
                                <Button
                                    variant="faded" 
                                    as={Link}
                                    href="build"
                                    size="lg"
                                    endContent={<FontAwesomeIcon icon={faHammer}/>}
                                    onClick={() => {
                                        appInsights.trackEvent({
                                            name: 'Purple Build Button'
                                        })
                                    }}
                                >
                                    Build
                                </Button>

                                <Button
                                    variant="bordered"
                                    className="text-black"
                                    as={Link}
                                    href="badges"
                                    size="lg"
                                    endContent={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
                                    onClick={() => {
                                        appInsights.trackEvent({
                                            name: 'Purple Browse Button'
                                        })
                                    }}
                                >
                                    Find 
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>

                <Spacer y={40} />

                <div>
                    <div id="links">
                        <div>
                            <div className="font-bold text-3xl mb-2">Links</div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                <Link href="build">Build</Link>
                                <Link href="badges">Badges</Link>
                                <Link href="pricing">Pricing</Link>
                            </div>
                        </div>

                        <div>
                            <div className="font-bold text-3xl mb-2">Resources</div>
                            <div>
                                <Link href="contact">Support</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <Spacer y={40} />

                <div className="p-4 lg:p10">
                    <FAQ />
                </div>  
            </div> 
        </div>
    )
}

function ForYouByYou() {
    const [word, setWord] = useState('For')
    return <span onAnimationIteration={() => setWord(word == 'For' ? `By` : 'For')} style={{display: 'inline-block', width: '65px', animationName: 'forbyyou', animationDuration: '1.5s', animationIterationCount: 'infinite', animationDirection: 'alternate'}}>{word}</span>
}

function LandingGrabber() {
    return (
        <div>
            <div style={{position: 'relative'}}>
                <BadgeStack />
            </div>
        </div>
    )
}

function BadgeStack() {
    const {data, isLoading} = useSWR('templates', () => getTemplates());
    const [templates, setTemplates] = useState(data);

    useEffect(() => {
        if(data == null) return;
        setTemplates(data.filter(x => !x.badge.landscape));
    }, [data])

    if(isLoading || templates == null) return <CircularProgress />

    const badge = (id: string) => {
        var badge = templates.filter(x => x.id == id)[0].badge;
        return (
            <Card>
                <div style={{display: 'flex'}}>
                    <Face face={badge.front} landscape={badge.landscape} lanyard={badge.lanyard} scollable={true} />
                </div>
            </Card>
        )
    } 

    return (
        <div id="badge-stack" style={{ display: 'flex', justifyContent: 'center'}}>
            <div style={{position: 'relative'}}>
                <div style={{zIndex: 0, display: 'flex'}}>
                    {badge("fnksdf092933")}
                </div>
                <div style={{zIndex: 1, display: 'flex', position: 'absolute', top: 0, transform: 'translate(-45%, -45%)'}}>
                    {badge("fnkj3298sd")}
                </div>
                <div style={{zIndex: 2, display: 'flex', position: 'absolute', top: 0, transform: 'translate(-90%, 15%)'}}>
                    {badge("59e93e6c-511b-4ca8-b362-e7b9d9dc4212")}
                </div>
                <div style={{zIndex: 3, display: 'flex', position: 'absolute', top: 0, transform: 'translate(45%, -15%)'}}>
                    {badge("sdfsfn23klsdf")}
                </div>
                <div style={{zIndex: 4, display: 'flex', position: 'absolute', top: 0, transform: 'translate(90%, 25%)'}}>
                    {badge("nsdfjh3ohsdf")}
                </div>
            </div>
        </div>
    )
}